<template>
  <div>
    <top-line-city title="Контакты" image="/BG.png" />
    <div class="container-fluid pt-5">
      <div class="row align-items-stretch">
        <div class="col-12 col-md-4 h-100" v-for="site in sites" :key="site.id">
          <div class="contact mb-4">
            <router-link
              :to="{ name: 'ContactPage', params: { site: site.id } }"
              tag="div"
              class="contact__image"
              :style="{
                backgroundImage: `url(${getFilePrefix}${site.image})`,
              }"
            />
            <div class="contact__content">
              <div class="contact__top d-flex justify-content-between">
                <router-link
                  tag="h2"
                  :to="{ name: 'ContactPage', params: { site: site.id } }"
                  class="contact__title"
                >
                  {{ site.name }}
                </router-link>
                <div class="contact__phone">
                  <a :href="`tel:${site.phone_2}`">{{ site.phone_2 }}</a>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-8">
                  <div class="contact__address">{{ site.address }}</div>
                  <div class="contact__address" v-html="site.working_hours" />
                </div>
                <div class="col-4 text-right">
                  <span class="contact__map" @click="activeSite = site"
                    >Показать на карте</span
                  >
                </div>
              </div>
              <div class="contact__buttons">
                <div class="row pt-3">
                  <div class="col-6">
                    <button class="button d-none">ЗАКАЗАТЬ ЗВОНОК</button>
                  </div>
                  <div class="col-6">
                    <router-link
                      :to="{ name: 'ContactPage', params: { site: site.id } }"
                      class="button"
                      >ПОДРОБНЕЕ
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <contact-map
        v-if="activeSite"
        :site="activeSite"
        @closeMap="activeSite = null"
      />
    </transition>
  </div>
</template>

<script>
import TopLineCity from "@/components/pages/TopLineCity";
import { mapGetters } from "vuex";
import ContactMap from "@/components/ContactMap";
export default {
  name: "Contacts",
  components: { ContactMap, TopLineCity },
  data() {
    return {
      sites: [],
      activeSite: null,
    };
  },
  head: {
    title: function () {
      return {
        inner: this.$route.meta?.title || "Прокат авто",
      };
    },
  },
  computed: {
    ...mapGetters("config", ["getSite", "getCity"]),
    getFilePrefix() {
      return process.env.VUE_APP_FILE_PATH;
    },
  },
  mounted() {
    this.loadMap();
  },
  methods: {
    async loadMap() {
      try {
        const response = await this.$axios.api.maps({});
        this.sites = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    async loadSites() {
      const payload = {
        id: {
          region: this.getCity,
        },
      };
      try {
        const response = await this.$axios.api.sitesByRegion(payload);
        this.sites = response.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.contact {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  &__image {
    min-height: 270px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  &__content {
    padding: 15px 25px;
  }
  &__title {
    font-weight: 700;
    font-size: em(20);
    color: #1a202c;
  }
  &__phone {
    a {
      font-weight: 700;
      font-size: em(16);
      text-align: right;
      color: #ff6600;
      text-decoration: none;
    }
  }
  &__address {
    font-weight: 700;
    font-size: em(12);
    color: #90a3bf;
  }
  &__address p {
    margin-bottom: 0px;
  }
  &__map {
    font-weight: 700;
    font-size: em(12);
    color: #ff6600;
    cursor: pointer;
    text-decoration: none;
  }
}
.text-right {
  text-align: right;
}
</style>
