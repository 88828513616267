<template>
  <div class="popup">
    <div
      class="popup__map maps"
      id="maps"
      ref="maps"
      style="height: 420px"
    ></div>
  </div>
</template>

<script>
import ymaps from "ymaps";
import { forEach } from "lodash";

export default {
  name: "ContactMap",
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    document.body.style.overflow = "hidden";
    document.getElementById("popup-bg").classList.add("active");
    document.addEventListener("click", this.clickToWrap);
    this.generateMap();
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
    document.getElementById("popup-bg").classList.remove("active");
    document.removeEventListener("click", this.clickToWrap);
  },
  methods: {
    clickToWrap(e) {
      if (e.target.classList.contains("popup_bg")) {
        this.$emit("closeMap");
      }
    },
    async generateMap() {
      try {
        const maps = await ymaps.load(
          "https://api-maps.yandex.ru/2.1/?apikey=14791924-ac81-4920-9d77-bac3bcc50308&lang=ru_RU"
        );
        const map = new maps.Map("maps", {
          center: [this.site.map_lat, this.site.map_lon],
          zoom: 10,
        });
        const objectManager = new maps.ObjectManager({
          clusterize: true,
        });
        const markers = [];
        map.geoObjects.add(objectManager);
        const cites = [this.site];
        forEach(cites, (site) => {
          let content = ``;
          if (site.image) {
            content += `<img src="${this.getFilePrefix}${site.image}" style="max-width: 100%;max-height: 150px" class="mb-2"/><br/>`;
          }
          content += `<h4>${site.name}</h4>`;
          content += `<b>Адрес:</b> ${site.address}<br/>`;
          content += `<b>Телефон:</b> <a href="tel:${site.phone}">${site.phone}</a><br/>`;
          if (site.phone_2) {
            content += `<b>Телефон:</b> <a href="tel:${site.phone_2}">${site.phone_2}</a><br/>`;
          }
          content += `<a href="/contacts/${site.id}" class="button mt-3">Подробнее</a>`;
          markers.push({
            type: "site",
            id: site.id,
            properties: {
              clusterCaption: site.name,
              balloonContentBody: content,
            },
            geometry: {
              type: "Point", // тип геометрии - точка
              coordinates: [site.map_lat, site.map_lon], // координаты точки
            },
          });
        });
        objectManager.add({ type: "FeatureCollection", features: markers });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.popup {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  min-height: 200px;
  width: 60vw;
  min-width: 300px;
  background-color: #fff;
  border-radius: 5px;
  position: fixed;
  z-index: 1000;
  overflow: hidden;
}
</style>
